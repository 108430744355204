import React, { useState } from "react";
import { Button, Form, Upload, message } from "antd";

import { errorNotification } from "components/result";
import Icons from "components/icons";
import { Localize } from "components/service";

import "./post-create-form.css";

const handleSubmit = (
  e,
  { createPost, createPostWithVideo, fileListImage, fileListVideo }
) => {
  e.preventDefault();

  const fileList = [...fileListImage, ...fileListVideo];

  const files = fileList.map(({ originFileObj }) => {
    return originFileObj;
  });

  if (fileListImage.length) {
    createPost({
      variables: {
        files,
      },
    }).catch((error) => {
      errorNotification(error);
    });
  }

  if (fileListVideo.length) {
    createPostWithVideo({
      variables: {
        file: files[0],
      },
    }).catch((error) => {
      errorNotification(error);
    });
  }
};

const NormalPostCreateForm = ({
  createPost,
  createPostWithVideo,
  loadingQueryCreatePost,
  loadingQueryWithVideo,
}) => {
  const [fileListImage, setFileListImage] = useState([]);
  const [fileListVideo, setFileListVideo] = useState([]);

  const formUploadPropsImage = {
    accept: "image/*",
    beforeUpload: () => {
      return false;
    },
    onChange: ({ fileList }) => setFileListImage(fileList),
    listType: "picture-card",
    fileList: fileListImage,
    showUploadList: {
      showPreviewIcon: false,
    },
    multiple: false,
  };

  const ruleFileMessage = Localize({
    children: "FORM_RULES.Text_UploadVideo",
    size: "125 MB",
  }).props.children;

  const formUploadPropsVideo = {
    accept: "video/*",
    beforeUpload: () => {
      return false;
    },
    onChange: ({ fileList }) => {
      if (fileList.length && fileList[0].size / 1024 / 1024 > 125) {
        message.error(ruleFileMessage);
        return false;
      }
      setFileListVideo(fileList);
    },
    listType: "picture-card",
    fileList: fileListVideo,
    showUploadList: {
      showPreviewIcon: false,
    },
    multiple: false,
  };

  return (
    <Form
      className={"post-create-form"}
      onSubmit={(e) => {
        handleSubmit(e, {
          createPost,
          createPostWithVideo,
          fileListImage,
          fileListVideo,
        });
      }}
    >
      <div className="holder-items">
        {fileListVideo.length < 1 && (
          <div className="col">
            <Form.Item
              className={`upload-holder ${
                fileListImage?.length < 3 ? "center-upload" : ""
              }`}
            >
              <Upload {...formUploadPropsImage}>
                {fileListImage < 1 && (
                  <div className="upload-label">
                    <div className="placeholder-icon">
                      <Icons.PlusNewImage />
                    </div>
                    <div className="site-icon">
                      <Localize>POST.Text_AddImage</Localize>
                    </div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Form.Item>
              <div className="upload-rules site-label-text-color">
                <Localize size={<span className="company-color">25 MB</span>}>
                  FORM_RULES.Text_UploadImage
                </Localize>
              </div>
            </Form.Item>
          </div>
        )}

        {fileListVideo.length < 1 && fileListImage.length < 1 && (
          <span className="selection-text">or</span>
        )}

        {fileListImage.length < 1 && (
          <div className="col">
            <Form.Item
              className={`upload-holder ${
                fileListVideo?.length < 3 ? "center-upload" : ""
              }`}
            >
              <Upload {...formUploadPropsVideo}>
                {fileListVideo < 1 && (
                  <div className="upload-label">
                    <div className="placeholder-icon">
                      <Icons.VideoSroke />
                    </div>
                    <div className="site-icon">
                      <Localize>POST.Text_AddVideo</Localize>
                    </div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Form.Item>
              <div className="upload-rules site-label-text-color">
                <div style={{ marginBottom: "15px" }}>
                  <Localize size={<span className="company-color">20</span>}>
                    FORM_RULES.Text_UploadVideoLongerThan
                  </Localize>
                </div>
                {fileListVideo.length >= 1 && (
                  <div>
                    <Localize size={<span className="company-color">20</span>}>
                      FORM_RULES.Text_UploadVideoSpeed
                    </Localize>
                  </div>
                )}
              </div>
            </Form.Item>
          </div>
        )}
      </div>
      <Form.Item>
        <div className="form-actions">
          <Button
            className="rounded-button"
            type="primary"
            htmlType="submit"
            loading={loadingQueryCreatePost || loadingQueryWithVideo}
            disabled={fileListImage.length === 0 && fileListVideo.length === 0}
          >
            <Localize wrap>GLOBAL.Button_Text_Next</Localize>
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

const PostCreateForm = Form.create({ name: "validate_other" })(
  NormalPostCreateForm
);

export default PostCreateForm;
