import React, { useState } from 'react';
import { useApolloClient } from "@apollo/react-hooks";

import { GET_ME } from "graphql/query/user-gql";

import { CardHero, CardMediaSlider, CardLikeAmount, CardActions } from "components/card";
import { CommentsDrawer, CommentsList, CommentForm } from "components/comments";
import { AttachmentsPopup } from "components/attachments";
import { Localize, MoreText } from "components/service";
import Icons from "components/icons";

import './card-post.css';
import {Link} from "react-router-dom";


const CardPost = (props) => {

    const client = useApolloClient();
    const { me } = client.readQuery({query: GET_ME});

    const { post, relationship_info } = props;
    const {
        attached_file_count,
        comment_count,
        created_at,
        comments,
        creator
    } = post;

    const [ showCommentsDrawer, setShowCommentsDrawer ] = useState(false);

    return (
        <div id={ `card-post-${post.id}` } className={`card-post ${ post.type }`}>
            <CardHero
                relation={ relationship_info }
                post={ post }
                me={me}
            />
            <div className="card-body">
                <CardMediaSlider
                    media={ post.media }
                    postId={ post.id }
                    badge={ post.type === "news" &&
                        <div className="badge">
                            <Icons.News type="platform"/>
                        </div>
                    }
                />
                <CardActions
                    postId={ post.id }
                    postSlug={ post.slug }
                    liked={ post.liked }
                    setShowCommentsDrawer={ setShowCommentsDrawer }
                />
                <div className="card-add-info">
                    <CardLikeAmount
                        likeCount={ post.like_count }
                        postId={ post.id }
                        myId={ me.id }
                    />
                </div>
                <div className="card-text">
                    { post.title ? <h3 className="card-title">{ post.title }</h3> : null }
                    <MoreText
                        text={ post.description }
                        length={ 128 }
                    />
                    { creator &&
                        <Localize
                            wrap={"p"}
                            wrapClass="post-origin"
                            username={
                                <Link to={`/${creator.nickname}`} className="site-text-color">
                                    <b>@{ creator.nickname }</b>
                                </Link>
                            }
                        >
                            POST.Text_Origin
                        </Localize>

                    }

                </div>
                { attached_file_count > 0 &&
                    <div className="card-attachments">
                        <AttachmentsPopup
                            attached_file_count={ attached_file_count }
                            attachments={ post.attachments }
                        />
                    </div>
                }
                <div className={ `card-comments ${ comments.pageInfo.total > 0 ? '' : 'no-comments' }` }>
                    { comments.pageInfo.total >= 3 &&
                        <button
                            className="show-more site-sub-link-color"
                            onClick={ () => setShowCommentsDrawer(true)  }
                        >
                            <Localize commentCount={ comments.pageInfo.total } >
                                POST.Button_Text_ViewComments
                            </Localize>
                        </button>
                    }
                    { comment_count > 0 &&
                        <CommentsList
                            comments={comments.edges}
                            amount={ 2 }
                            withTime={ false }
                            withSimpleActions = { true }

                        />
                    }
                    <CommentsDrawer
                        setShowDrawer={ setShowCommentsDrawer }
                        showDrawer={ showCommentsDrawer }
                        postId={ post.id }
                    />
                </div>
            </div>
            <div className="card-footer">
                <div className="card-footer-wrap">
                    <CommentForm
                        postId={ post.id }
                        postStatus={ post.status }
                        lastCommentId={
                            comments.pageInfo.total > 0 ?
                                comments.edges[0].node.id : ""
                        }
                    />
                    <div className="post-created site-sub-text-color">{created_at}</div>
                </div>
            </div>
        </div>
    );
};

export default CardPost;
