import React from "react";
import {Link} from "react-router-dom";

import AvatarCustom from "../avatar-custom";
import { Localize } from "components/service";
import Icons from "../../icons";


const AvatarThreeRow = (props) => {

    const {
        title,
        text,
        nickname,
        link,
        type,
        diamondStatus = false,
    } = props;

    if(type === "two"){
        return (
            <AvatarCustom { ...props } holderClass={ 'three-row' }>
                 <div style={{display: 'flex', alignItems: 'center'}}>
                    { nickname &&
                    <div className="author" style={{marginBottom: 0}}>
                        <Link to={`/${nickname}`} className="site-text-color">
                            <b>{nickname}</b>
                        </Link>
                    </div>
                    }
                    &nbsp;&nbsp;
                    { diamondStatus &&
                        <div className="diamond-status">
                            <Localize wrap>GLOBAL.Text_DiamondStatus</Localize> <Icons.Diamond/>
                        </div>
                    }
                </div>

                { (title !== null || text !== null) &&
                <div className="company-children">
                    { title !== null &&
                        <p className="text">{ title }</p>
                    }
                    { text !== null &&
                        <p className="text site-sub-text-light-color">{ text }</p>
                    }
                </div>
                }
            </AvatarCustom>
        )
    }

    return (
        <AvatarCustom { ...props } holderClass={'three-row'}>
            { nickname &&
                <div className="author">
                    <Link to={`/${nickname}`} className="site-text-color">
                        <Icons.User filled />
                        <span>{nickname}</span>
                    </Link>
                </div>
            }
            { (title !== null || text !== null) &&
                <Link to={ link } className="company-children-hover-color">
                    { title !== null &&
                        <h3 className="title">{ title }</h3>
                    }
                    { text !== null &&
                        <div className="text site-text-color">{ text }</div>
                    }
                </Link>
            }
        </AvatarCustom>
    );
};

export default AvatarThreeRow;
