import React from "react";
import { Link } from "react-router-dom";
import { Localize } from "components/service";


import AvatarCustom from "../avatar-custom";
import Icons from "../../icons";


const AvatarBlock = (props) => {

    const {
        label,
        link,
        text,
        labelLink = !!link,
        avatarAction,
        diamondStatus = false,
    } = props;

    const labelItem = labelLink ? <Link to={ link } className="avatar-link site-title-color">{ label }</Link> : label;

    return (
        <AvatarCustom { ...props }>
            <p className="avatar-label site-title-color">
                { labelItem }
            </p>
            { text !== undefined &&
                <div className="avatar-text site-sub-text-color">
                    { text }
                </div>
            }
            { avatarAction !== undefined &&
                <div className="avatar-action company-link-hover">
                    { avatarAction }
                </div>
            }
            { diamondStatus &&
                <div className="diamond-status">
                    <Localize wrap>GLOBAL.Text_DiamondStatus</Localize> <Icons.Diamond/>
                </div>
            }
        </AvatarCustom>
    );
};

export default AvatarBlock;
